import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, UserGuard } from 'src/auth/guard';

// layouts
import MainLayout from 'src/layouts/main/layout';
// components
import {
  LoadingScreen,
  // SplashScreen
} from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// PRODUCT
const ProductListPage = lazy(() => import('src/pages/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/product/new'));
const ProductEditPage = lazy(() => import('src/pages/product/edit'));
const ProductCheckoutPage = lazy(() => import('src/pages/product/checkout'));
// USER
const UserProfilePage = lazy(() => import('src/pages/account/user/profile'));
const UserAccountPage = lazy(() => import('src/pages/account/user/account'));
// PAYMENT PRICING
// const PricingPage = lazy(() => import('src/pages/pricing'));
// const PaymentPage = lazy(() => import('src/pages/payment'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/account/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/account/blank'));

// ----------------------------------------------------------------------

export const PrivateRoutes = [
  {
    path: 'home',
    element: (
      <AuthGuard>
        <MainLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          {
            path: 'profile/:id',
            element: (
              <UserGuard>
                <UserProfilePage />
              </UserGuard>
            ),
          },
          { path: 'account/:id', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
          { path: 'checkout', element: <ProductCheckoutPage /> },
        ],
      },
      // {
      //   element: (
      //     <SimpleLayout>
      //       <Suspense fallback={<SplashScreen />}>
      //         <Outlet />
      //       </Suspense>
      //     </SimpleLayout>
      //   ),
      //   children: [
      //     { path: 'pricing', element: <PricingPage /> },
      //     { path: 'payment', element: <PaymentPage /> },
      //   ],
      // },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
