import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
import CompactLayout from 'src/layouts/compact';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import('src/pages/home'));
const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const FaqsPage = lazy(() => import('src/pages/faqs'));
const AboutPage = lazy(() => import('src/pages/about-us'));
const ContactPage = lazy(() => import('src/pages/contact-us'));
const ComingSoonPage = lazy(() => import('src/pages/coming-soon'));
const MaintenancePage = lazy(() => import('src/pages/maintenance'));
const TermsAndConditionsPage = lazy(() => import('src/pages/terms-conditions'));
const PrivacyPolicyPage = lazy(() => import('src/pages/privacy'));
// PRODUCT
const ProductListPage = lazy(() => import('src/pages/product/shop'));
const ProductDetailsPage = lazy(() => import('src/pages/product/details'));
// USER
const UserProfilePage = lazy(() => import('src/pages/account/user/profile'));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      { path: 'about-us', element: <AboutPage /> },
      { path: 'contact-us', element: <ContactPage /> },
      { path: 'terms-conditions', element: <TermsAndConditionsPage /> },
      { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
      { path: 'faqs', element: <FaqsPage /> },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: ':id', element: <UserProfilePage /> },
        ],
      },
      {
        path: 'product',
        children: [
          { path: '', element: <Navigate to="/" />, index: true },
          { path: 'category', element: <ProductListPage /> },
          { path: 'search', element: <ProductListPage showCategory /> },
          { path: ':id', element: <ProductDetailsPage /> },
        ],
      },
    ],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: '/coming-soon', element: <ComingSoonPage /> },
      { path: 'maintenance', element: <MaintenancePage /> },
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
];
