import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
// hooks
import { useParams } from 'src/routes/hooks';

export default function UserGuard({ children }) {
  const { id } = useParams();

  const loggedInUserId = useSelector((state) => state.auth?.currentUser?._id);

  if (id !== loggedInUserId) {
    return <Navigate to="/" replace />;
  }

  return children;
}

UserGuard.propTypes = {
  children: PropTypes.element,
};
