import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';

//
import IconButton from '@mui/material/IconButton';
import SvgColor from '../../svg-color';

// ----------------------------------------------------------------------

export default function BaseOptions({ icons, value, onChange, sx }) {
  const selected = value === 'light' ? 'dark' : 'light';

  return (
    <IconButton
      onClick={() => onChange(selected)}
      sx={{
        border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.08)}`,
        ...(selected && {
          bgcolor: 'background.paper',
          boxShadow: (theme) =>
            `-24px 8px 24px -4px ${alpha(
              theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
              0.08
            )}`,
        }),
        '& .svg-color': {
          background: (theme) =>
            `linear-gradient(135deg, ${theme.palette.grey[500]} 0%, ${theme.palette.grey[600]} 100%)`,
          ...(selected && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        },
        ...sx,
      }}
    >
      <SvgColor src={`/assets/icons/setting/ic_${selected === 'light' ? 'sun' : 'moon'}.svg`} />
    </IconButton>
  );
}

BaseOptions.propTypes = {
  icons: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  value: PropTypes.string,
  sx: PropTypes.object,
};
