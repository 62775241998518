import { createContext, useContext } from 'react';

// ----------------------------------------------------------------------

export const ViewsContext = createContext({});

export const useViewsContext = () => {
  const context = useContext(ViewsContext);

  if (!context) throw new Error('useViewsContext must be use inside ViewsProvider');

  return context;
};
