// routes
import { paths } from 'src/routes/paths';

// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="material-symbols:home-rounded" />,
    path: '/',
  },
  {
    title: 'About',
    icon: <Iconify icon="mdi:about" />,
    path: paths.about,
  },
  {
    title: 'Contact',
    icon: <Iconify icon="fluent:contact-card-20-filled" />,
    path: paths.contact,
  },
];

export const navDesktop = [
  ...navConfig,
  {
    title: 'Sell',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: paths.dashboard.product.new,
    isButton: true,
  },
];

export const navMobile = navConfig;
