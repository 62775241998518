import React from 'react';
import { useSelector } from 'react-redux';
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgBlur } from 'src/theme/css';
// components
import IconButton from '@mui/material/IconButton';
import FullLogo from 'src/components/logo';
import BaseOptions from 'src/components/settings/drawer/base-option';
//
import { useSettingsContext } from 'src/components/settings';
import Iconify from 'src/components/iconify';
import { paths } from 'src/routes/paths';
import { HEADER } from '../config-layout';
import { navDesktop } from './config-navigation';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';

//
import { AccountPopover, HeaderShadow, LoginButton } from '../_common';

// ----------------------------------------------------------------------

const Header = () => {
  const theme = useTheme();

  const settings = useSettingsContext();

  const { isAuthenticated } = useSelector((state) => state.auth);

  const mdUp = useResponsive('up', 'md');

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
          {/* {mdUp ? ( */}
          <FullLogo />
          {/* ) : (
            <IconButton sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <MiniLogo />
            </IconButton>
          )} */}
          <Box sx={{ flexGrow: 1 }} />

          {mdUp && <NavDesktop offsetTop={offsetTop} data={navDesktop} />}

          <Stack alignItems="center" spacing={1} direction={{ xs: 'row' }}>
            {mdUp && (
              <BaseOptions
                value={settings.themeMode}
                onChange={(newValue) => settings.onUpdate('themeMode', newValue)}
                options={['light', 'dark']}
                icons={['sun', 'moon']}
              />
            )}
            {!mdUp && (
              <IconButton
                sx={{
                  backgroundColor: theme.palette.primary.main, // Use the main color from the theme
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark, // Use a darker variant for hover state
                  },
                  color: 'black',
                }}
                href={paths.dashboard.product.new}
              >
                <Iconify icon="tabler:plus" width={24} />
              </IconButton>
            )}
            {isAuthenticated ? (
              <AccountPopover />
            ) : (
              <LoginButton isAuthenticated={isAuthenticated} />
            )}

            {!mdUp && <NavMobile offsetTop={offsetTop} data={navDesktop} />}
          </Stack>
        </Container>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
};

export default React.memo(Header);
