export const _socials = [
  {
    value: 'facebook',
    name: 'FaceBook',
    icon: 'eva:facebook-fill',
    color: '#1877F2',
    path: 'https://www.facebook.com/profile.php?id=100089356614000&sk=about',
  },
  // {
  //   value: 'instagram',
  //   name: 'Instagram',
  //   icon: 'ant-design:instagram-filled',
  //   color: '#E02D69',
  //   path: 'https://www.instagram.com/caitlyn.kerluke',
  // },
  // {
  //   value: 'linkedin',
  //   name: 'Linkedin',
  //   icon: 'eva:linkedin-fill',
  //   color: '#007EBB',
  //   path: 'https://www.linkedin.com/caitlyn.kerluke',
  // },
  // {
  //   value: 'x',
  //   name: 'X',
  //   icon: 'fa6-brands:square-x-twitter',
  //   color: '#00AAEC',
  //   // path: 'https://www.x.com/caitlyn.kerluke',
  // },
];
