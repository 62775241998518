import React from 'react';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';

const MiniLogo = React.memo(({ disabledLink = false, sx }) => {
  const logo = (
    <Box
      component="div"
      draggable="false"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        width="40"
        height="50"
        viewBox="0 0 480.15 568.4"
        xmlSpace="preserve"
      >
        <defs>
          <style>{'.cls-1{fill:#faab4b}.cls-2{fill:#5695cf}'}</style>
        </defs>
        <path
          d="m399.25 317.34-34.09-16.58c9.23-18.98 13.91-39.4 13.91-60.69 0-76.64-62.35-138.99-138.99-138.99s-138.99 62.35-138.99 138.99c0 21.17 4.63 41.49 13.77 60.39l-34.13 16.49C69.09 292.86 63.19 267 63.19 240.06c0-97.54 79.36-176.9 176.9-176.9s176.9 79.36 176.9 176.9c0 27.08-5.96 53.07-17.72 77.26Z"
          className="cls-2"
        />
        <path
          d="M137.58 289.39c-7.45-15.46-11.22-32.05-11.22-49.31 0-62.71 51.01-113.72 113.72-113.72S353.8 177.37 353.8 240.08c0 16.87-3.61 33.11-10.73 48.28l-34.31-16.11c4.74-10.09 7.14-20.91 7.14-32.17 0-41.8-34.01-75.81-75.81-75.81s-75.81 34.01-75.81 75.81c0 11.52 2.51 22.58 7.47 32.86L137.6 289.4Z"
          className="cls-2"
        />
        <path
          d="m455.19 346.78-33.95-16.87c13.94-28.04 21-58.27 21-89.84 0-111.48-90.69-202.17-202.17-202.17S37.91 128.6 37.91 240.08c0 31.1 6.87 60.91 20.41 88.63l-34.06 16.64C8.16 312.4 0 276.99 0 240.08c0-64.13 24.97-124.41 70.32-169.76S175.95 0 240.08 0s124.41 24.97 169.76 70.32c45.34 45.34 70.32 105.63 70.32 169.76 0 37.47-8.4 73.38-24.96 106.71Z"
          className="cls-2"
        />
        <path
          d="m224.11 409.34-.24.24-.04-.04c.1-.06.19-.13.28-.2ZM450.69 353.32l-98.3 98.79.04.04-26.17 26.34L256.33 409l26.13-26.29h-.01l86.09-86.54c3.43-3.45 8.19-5.59 13.44-5.59 10.47 0 18.95 8.49 18.95 18.96 0 5.21-2.1 9.93-5.51 13.36l-.04.04-86 86.44 16.12 16.01 98.25-98.73c3.47-3.86 8.5-6.28 14.09-6.28 10.47 0 18.96 8.48 18.96 18.95 0 5.53-2.37 10.5-6.14 13.96l.03.03ZM222.59 322.17l-26.74 26.88-54.37-54.1a18.914 18.914 0 0 1-5.58-13.44c0-10.47 8.48-18.95 18.95-18.95 5.23 0 9.94 2.11 13.37 5.52l.03.03 54.34 54.06Z"
          className="cls-1"
        />
        <path
          d="M344.44 281.16c0 5.23-2.12 9.97-5.55 13.4L224.11 409.34c-.09.07-.18.14-.28.2-.61.46-1.27.92-1.96 1.36-15.38 9.76-39.27 7.32-42.48-.73-2.45-6.13 6.3-17.51 17.72-27.34l-.05-.05 115.03-115.03c3.43-3.43 8.16-5.55 13.4-5.55 10.47 0 18.95 8.49 18.95 18.96Z"
          className="cls-1"
        />
        <path
          d="M230.69 402.76c-.15.22-.31.43-.48.64a34.182 34.182 0 0 1-6.1 5.94l6.58-6.58ZM316.66 507.46c0 10.47-8.49 18.96-18.95 18.96-5.37 0-10.23-2.24-13.67-5.83l-.03.03-69.94-69.49 26.72-26.89 69.43 68.99c3.95 3.47 6.44 8.56 6.44 14.23ZM181.4 365.53l-26.74 26.87-70.02-69.66a18.914 18.914 0 0 1-5.58-13.44c0-10.46 8.48-18.95 18.95-18.95 5.23 0 9.94 2.11 13.37 5.52l.03.03 69.99 69.63ZM273.49 549.44c0 10.47-8.49 18.96-18.95 18.96-5.31 0-10.11-2.18-13.56-5.7l-.08.08L28.51 351.46c-3.55-3.46-5.76-8.28-5.76-13.62 0-10.46 8.48-18.95 18.95-18.95 5.31 0 10.11 2.18 13.55 5.7l.06.06 211.04 209.98c4.35 3.47 7.14 8.82 7.14 14.81Z"
          className="cls-1"
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href={paths.root} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

MiniLogo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default MiniLogo;
