import PropTypes from 'prop-types';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// utils
import { axiosInstanceNew } from 'src/utils/axios';

// redux
import { initialized } from 'src/redux/slices/auth';

// ----------------------------------------------------------------------

export function AuthInitializer({ children }) {
  const dispatch = useDispatch();

  // Get the initialization state from Redux
  const isInitialized = useSelector((state) => state.auth.isInitialized);

  // Function to verify access token in the cookies with the backend
  const checkTokenValidity = async () => {
    try {
      const response = await axiosInstanceNew.get('api/client/user/verifytoken');
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const initialize = useCallback(async () => {
    // Only run initialization if it hasn't already been done
    if (!isInitialized) {
      const validUser = await checkTokenValidity();
      if (validUser !== null) {
        dispatch(
          initialized({
            isAuthenticated: true,
            user: validUser,
          })
        );
      } else {
        dispatch(
          initialized({
            isAuthenticated: false,
            user: null,
          })
        );
      }
    }
  }, [dispatch, isInitialized]);

  useEffect(() => {
    if (!isInitialized) {
      initialize();
    }
  }, [isInitialized, initialize]);

  return <>{children}</>;
}

AuthInitializer.propTypes = {
  children: PropTypes.node,
};
