import PropTypes from 'prop-types';
import { useMemo, useCallback } from 'react';
// hooks
import { useLocalStorage, getStorage } from 'src/hooks/use-local-storage';
import { ViewsContext } from './view-context';

// ----------------------------------------------------------------------

export const STORAGE_KEY = 'VIEWS_HARAASH_78489028';

const initialState = {};

export function ViewsProvider({ children }) {
  const { state, update } = useLocalStorage(STORAGE_KEY, initialState);

  const onCheckViews = useCallback((productId, userId) => {
    const key = userId ? `viewedProducts_${userId}` : 'guestViewedProducts';

    const storedData = getStorage(STORAGE_KEY);

    if (!storedData || !storedData[STORAGE_KEY]) {
      return false;
    }

    const viewsData = storedData[STORAGE_KEY];

    const viewedProducts = viewsData[key] || [];

    if (!Array.isArray(viewedProducts)) {
      return false;
    }

    const hasViewed = viewedProducts.includes(productId);

    return hasViewed;
  }, []);

  const onAddView = useCallback(
    (productId, userId) => {
      let key = 'guestViewedProducts';
      if (userId) {
        key = `viewedProducts_${userId}`;
      }

      const getProducts = getStorage(STORAGE_KEY) || {};

      const viewedProducts = getProducts[key] || [];

      if (!viewedProducts.includes(productId)) {
        viewedProducts.push(productId);

        const updatedProducts = {
          ...getProducts,
          [key]: viewedProducts,
        };

        update(STORAGE_KEY, updatedProducts);
      }
    },
    [update]
  );

  const memoizedValue = useMemo(
    () => ({
      ...state,
      onAddView,
      onCheckViews,
    }),
    [onCheckViews, onAddView, state]
  );

  return <ViewsContext.Provider value={memoizedValue}>{children}</ViewsContext.Provider>;
}

ViewsProvider.propTypes = {
  children: PropTypes.node,
};
